import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    margin: 30px 0;
    max-width: 600px;

    .banner {
        width: 100%;
        margin-bottom: 10px;
    }

    .ul-list {
        width: 100%;
        padding: 20px 40px;
        li {
            font-size: var(--unnamed-font-size-14);
            line-height: var(--unnamed-line-spacing-24);
            color: var(--unnamed-color-4a3f32);
        }
    }

    .title-info {
        margin: 30px 0;
        font-style: var(--unnamed-font-style-normal);
        font-weight: var(--unnamed-font-weight-600);
        font-size: var(--unnamed-font-size-18);
        line-height: var(--unnamed-line-spacing-18);
        letter-spacing: var(--unnamed-character-spacing-1);
        color: var(--unnamed-color-d5b68f);
        text-transform: uppercase;
    }

    .description {
        font-weight: var(--unnamed-font-style-normal);
        font-size: var(--unnamed-font-size-14);
        line-height: var(--unnamed-line-spacing-24);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-4a3f32);
        margin: 0;
    }

    .box-button-link {
        display: flex;
        justify-content: flex-end;
        padding-top: 40px;

        .button-link {
            padding: 15px 30px;
            background-color: #ffffff;
            font: var(--unnamed-font-style-normal) normal bold var(--unnamed-font-size-14)/var(--unnamed-line-spacing-14) var(--unnamed-font-family-raleway);
            color: var(--unnamed-color-4a3f32);
            text-align: left;
            font: normal normal bold 14px/24px Raleway;
            letter-spacing: 1.96px;
            color: #4A3F32;
            text-transform: uppercase;
            border: 1px solid #4A3F32;
            cursor: pointer;

            :hover {
                background-color: #efefef7d;
            }
        }
    }

    @media (min-width: 800px) {
        ${(p) => p.type === 1 ? `
            max-width: 1400px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row-reverse;

            .div-info-men {
                width: 100%;
                max-width: 400px;
            }

            .banner {
                width: 60%;
                margin-bottom: 10px;
            }
        }
        ` : p.type === 2 ? `@media (min-width: 800px) {
            height: 80vw;
            max-width: 1400px;
            max-height: 105vh;
            background-image: url('https://adrianmaycon.github.io/static/media/image4.876726fd.png')};
            background-size: cover;
            background-position: center; 
            background-repeat: no-repeat;
            padding: 40px 67px;
            margin: 60px 0;
            
            .div-info-men {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            .tam-txt-info {
                width: 100%;
                max-width: 200px;
            }

            .banner {
                position: none;
                display: none;
            }
        ` : null}
    }
`;