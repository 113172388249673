import React, { useState } from 'react';

/** 
 * Adriano Reis - Modificações.
 */

// Imagem Sobre
import sobreImage from '../../assets/images/Sobre-aille-clinic.webp';

// Imagem do slide de Nossas Unidades.
import nossasUnidadesImg1 from "../../assets/images/banner2.jpg";
import nossasUnidadesImg2 from "../../assets/images/nossasunidades2.webp";
import nossasUnidadesImg3 from "../../assets/images/nossas_unidades_3.webp";
import nossasUnidadesImg4 from "../../assets/images/nossas_unidades_4.webp";
import nossasUnidadesImg5 from "../../assets/images/nossas_unidades_5.webp";
import nossasUnidadesImg6 from "../../assets/images/nossas_unidades_6.webp";
import nossasUnidadesImg7 from "../../assets/images/nossas_unidades_7.webp";
import nossasUnidadesImg8 from "../../assets/images/nossas_unidades_8.webp";
import nossasUnidadesImg9 from "../../assets/images/nossas_unidades_9.webp";
import nossasUnidadesImg10 from "../../assets/images/nossas_unidades_10.webp";
import nossasUnidadesImg11 from "../../assets/images/nossas_unidades_11.webp";
import nossasUnidadesImg12 from "../../assets/images/nossas_unidades_12.webp";

// ___________________________________

import logo from '../../assets/images/logo_ailleclinic.svg';
import drMurillo from '../../assets/images/dr-murillo.webp';
import draAlessandra from '../../assets/images/dra-alessandra.webp';
import image3 from '../../assets/images/image3.PNG';
import image4 from '../../assets/images/image4.webp';
import bannerHome from '../../assets/images/banner2.jpg';
import CardProf from '../../components/CardProf';
import CardText from '../../components/CardText';
import CardInfo from '../../components/CardInfo';
import CardStore from '../../components/CardStore';
import Menu from '../../components/Menu';
import Footer from '../../components/Footer';
import { IoReorderTwoOutline, IoCloseOutline } from "react-icons/io5";
import { Container, Banner } from './styled';

const dataText = [
    {
        image: sobreImage,
        title: ["SOBRE A AILLE"],
        subtitle: "",
        menssage: [
            "De origem celta, um dos idiomas mais antigos do mundo, Aille significa algo ou alguém de grande beleza.",
            "Tendo a premissa do belo na raiz de seu nome, a clínica traz o extremo rigor cientifico e a alta tecnologia à prática da dermatologia e cirurgia plástica.",
            "A qualidade é responsabilidade nossa e se traduz em detalhes desde o ambiente até a equipe médica amplamente especializada e os tratamentos mais avançados.",
            "É a medicina e a beleza sob o ideal da excelência e da sofisticação.",
        ],
        type: 1,
    },
    {
        image: [
            nossasUnidadesImg1,
            nossasUnidadesImg2,
            nossasUnidadesImg3,
            nossasUnidadesImg4,
            nossasUnidadesImg5,
            nossasUnidadesImg6,
            nossasUnidadesImg7,
            nossasUnidadesImg8,
            nossasUnidadesImg9,
            nossasUnidadesImg10,
            nossasUnidadesImg11,
            nossasUnidadesImg12,

        ],
        title: ["Nossas Unidades"],
        subtitle: "",
        menssage: [

        ],
        type: 2,
    },
]

const dataProfi = [
    {
        id: 1,
        image: drMurillo,
        name: "DR. MURILLO FRAGA",
        profession: "Cirurgião Plástico",
        description: "Dr Murillo Fraga fez a sua graduação e residência médica em cirurgia plástica na Faculdade de Ciências Médicas da Santa Casa de São Paulo. No ano de 2007, obteve o título de mestrado e em 2010, o de doutorado. Atualmente, atua e é diretor na Aille Clinic, é professor assistente da Faculdade de Medicina da Santa Casa de São Paulo e clinica nos hospitais Albert Einstein e Sírio-Libanês. É autor de diversos capítulos de livros e de artigos científicos, publicados nas principais revistas nacionais e internacionais da especialidade.",
        linkedin: "http://buscatextual.cnpq.br/buscatextual/visualizacv.do?metodo=apresentar&id=K4233200U5",
        instagram: "https://www.instagram.com/drmurillofraga/",
        crm: 'CRM-105.339',
        rqe: 'RQE: 29.085',
    },
    {
        id: 2,
        image: draAlessandra,
        name: "DRA. ALESSANDRA FRAGA",
        profession: "Dermatologista",
        description: "Dra Alessandra Fraga é médica formada pela Faculdade de Ciências Médicas da Santa Casa de SP, com residência médica em dermatologia na mesma instituição. Especializada em laser na Harvard Medical School e em oncologia cutânea na Itália. Atualmente atua e é diretora da Aille Clinic e Clínica no Hospital Israelita Albert Einstein. Membro titular da Sociedade Brasileira de Dermatologia e da American Academy of Dermatology.",
        linkedin: "#",
        instagram: "https://www.instagram.com/draalessandrafraga/",
        crm: 'CRM-120.489',
    },
]

const dataInfo = [
    {
        id: 1,
        type: 1,
        image: image3,
        title: "Tratamentos",
        description: "Com corpo clínico especializado e em constante atualização para atender aos protocolos de tratamento mais modernos, a Aille Clinic realiza diagnóstico, tratamentos, rastreamento e  prevenção de doenças da pele, mucosa, unhas e cabelos.",
        buttonLabel: "",
        ulList: ["Doenças da pele", "Cabelos", "Unhas", "Oncologia Cutânea", "Mapeamento corporal total / Dermatoscopia Digital."],
        buttonHref: "",
        idLink: "tratamentos",
        icons: "ss"
    },
]

const dataStore = [
    {
        id: 2,
        type: 2,
        image: image4,
        title: "Aille Store",
        description: "Aille clinic medical cosmetics traz a experiência dermatológica e altas concentrações de princípios ativos terapêuticos para cada frasco de seus cosméticos. Com alta performance, essa linha de skincare foi desenvolvida pela dermatologista Alessandra Fraga e pelo cirurgião plástico, Murillo Fraga, que inauguraram no Brasil o conceito de cosmético médico - aquele que contém ingredientes em concentrações efetivamente capazes de tratar e modificar a condição da pele! ",
        buttonLabel: "Visite a nossa loja",
        ulList: [""],
        buttonHref: "https://aillecosmetics.com.br/",
        idLink: "aille-store",
        icons: ""
    },

]


function Home() {

    const [open, setOpen] = useState(false)

    function menuFechando() {
        setOpen(false);
        let boxLogo = document.querySelector('.logo-and-text');
        let title = document.querySelector('.subtitleJS');

        if (window.outerWidth < 800) {
            boxLogo.classList.remove('menu-aberto');
            boxLogo.classList.add('menu-fechado');
        } else {
            boxLogo.classList.remove('menu-aberto-desktop');
            title.classList.remove('titulo-hidden');
        }
    }

    function menuAbrindo() {
        setOpen(true);
        let boxLogo = document.querySelector('.logo-and-text');
        let title = document.querySelector('.subtitleJS');

        if (window.outerWidth < 800) {
            boxLogo.classList.add('menu-aberto');
            boxLogo.classList.remove('menu-fechado');
        } else {
            title.classList.add('titulo-hidden');
            boxLogo.classList.add('menu-aberto-desktop');
        }
    }

    return (
        <Container>
            <header>
                <Banner image={bannerHome} />

                <div className="box-center">
                    <div className="container-menu">
                        {open ? <IoCloseOutline className="icon-menu" onClick={() => menuFechando()} /> : <IoReorderTwoOutline className="icon-menu" onClick={() => menuAbrindo()} />}
                        <Menu open={open} />
                    </div>
                    <div className='logo-and-text'>
                        <img className="logo-ailleclinic" src={logo} alt="Logo Aille Clinic" />
                        <span className='text-technical-manager'>Responsável Técnico <br /> Dra. Alessandra Fraga; CRM/SP 120.489</span>
                    </div>
                    <h2 className="subtitle subtitleJS">role a página e conheça</h2>
                </div>
            </header>

            <main>
                <div id="sobre">
                    {dataText.map((text) =>
                        <CardText
                            key={text.title}
                            image={text.image}
                            title={text.title}
                            subtitle={text.subtitle}
                            type={text.type}
                            menssage={text.menssage}
                        />
                    )}
                </div>
                <div className="cont-prof" id="corpo-clinico">
                    {dataProfi.map((prof) =>
                        <CardProf
                            key={prof.id}
                            name={prof.name}
                            image={prof.image}
                            profession={prof.profession}
                            description={prof.description}
                            linkedin={prof.linkedin}
                            instagram={prof.instagram}
                            crm={prof.crm}
                            rqe={prof.rqe}
                        />
                    )}
                </div>

                {dataInfo.map((info) =>
                    <CardInfo
                        key={info.id}
                        id={info.idLink}
                        type={info.type}
                        image={info.image}
                        title={info.title}
                        ulList={info.ulList}
                        description={info.description}
                        buttonLabel={info.buttonLabel}
                        buttonHref={info.buttonHref}
                        icons={info.icons}
                    />
                )}

                {dataStore.map((info) =>
                    <CardStore
                        key={info.id}
                        id={info.idLink}
                        type={info.type}
                        image={info.image}
                        title={info.title}
                        ulList={info.ulList}
                        description={info.description}
                        buttonLabel={info.buttonLabel}
                        buttonHref={info.buttonHref}
                        icons={info.icons}
                    />
                )}

            </main>

            <Footer />

        </Container>
    );
}

export default Home;
