import React from 'react';
import { ContainerMenu } from './styled';

function Menu({ open }) {
    let menuItens = [
        {
            name: 'Sobre',
            link: '#sobre'
        }, {
            name: 'A Clinica',
            link: '#a-clinica'
        },
        {
            name: 'Corpo Clínico',
            link: '#corpo-clinico'
        },
        {
            name: 'Tratamentos',
            link: '#tratamentos'
        },
        {
            name: 'Aille Store',
            link: '#aille-store'
        },
        {
            name: 'Contato',
            link: '#contato'
        }
    ]

    return (
        <ContainerMenu open={open}>
            <div className="menu-list-wrapper">
                <ul className="menu-list">
                    {menuItens.map((item) =>
                        <li class="list-item">
                            <a href={item.link}>{item.name}</a>
                        </li>
                    )}
                </ul>
            </div>
        </ContainerMenu>
    );
}

export default Menu;