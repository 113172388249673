import React from 'react';

import { Container } from './styled';
import IconHolder from '../IconHolder';

function CardStore({ type, image, title, description, buttonLabel, ulList, buttonHref, id, icons }) {
    return (
        <Container image={image} type={type}>
            <img className="banner" src={image} alt="" />

            <div className="div-info-men" id={id}>
                <div className="tam-txt-info">
                    <h2 className="title-info">{title}</h2>
                    <p className="description">{description}</p>
                </div>

                {type === 1 &&
                    <ul className="ul-list">
                        {ulList.map((li) => <li>{li}</li>)}
                    </ul>}

                {(icons) &&
                    <IconHolder />
                }
                {(buttonLabel && buttonHref) &&
                    <div className="box-button-link">
                        <a href={buttonHref} target="blank">
                            <button className="button-link" type="button">{buttonLabel}</button>
                        </a>

                    </div>
                }
            </div>

        </Container>
    );
}

export default CardStore;