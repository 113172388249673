import React from 'react';
import image5 from '../../assets/images/image5.webp';
import { ContainerFooter } from './styled';

function Footer() {
  return (
    <ContainerFooter>
      <div className="container-infos-contato">
        <img className="image-footer" src={image5} alt="" />

        <div className="container-posi-footer" id="contato">
          <div className="max-tam-footer">
            <h2 className="title">CONTATOS</h2>

            <p className="description">
              <b>
                UNIDADE I
              </b>
              <br />
              <br />

              End: Alameda Gabriel Monteiro da Silva, 1931<br />
              Jardim América<br />
              São Paulo - SP<br />
              Tel: 3062-4318 / 3082-9710<br />
              WhatsApp: (11) 950596453<br />
              <br />

              <b>
                UNIDADE II <br />
                Centro Avançado de Terapia Capilar<br />
                Centro Cirúrgico
              </b>
              <br />
              <br />

              End: Alameda Gabriel Monteiro da Silva, 1970<br />
              Jardim América<br />
              São Paulo - SP<br />
              Tel: 3062-4318 / 3082-9710<br />
              <br />

              Horário de funcionamento: <br />
              Segunda à sexta, das 8h às 20h. <br />
              Sábado das 9 às 13h.

              <br />
              <br />

              Envie um email com suas necessidades e dúvidas que retornaremos o mais breve possível: <b>contato@aille.com.br</b>
            </p>
          </div>
        </div>
      </div>

      <div className="container-direitos">
        <p>® <span>{(new Date().getFullYear())}</span> Clínica Aille - Todos os direitos reservados. Proibido reprodução total ou parcial do conteúdo. Site por <strong><a href="https://doo.is/pt/" className='signature' target='_blank' rel="noreferrer">doo.is</a></strong></p>
      </div>
    </ContainerFooter>
  );
}

export default Footer;