import styled from 'styled-components';

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow: auto;
    overflow-x:hidden;
    object-fit: cover;
    background-color: var(--color-background);
    scroll-behavior: smooth;

    header {
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 0 36px;

        .box-center {
            width: 100%;
            display: flex;
            min-height: 60vh;
            max-width: 600px;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            .logo-and-text {
                width: 100vw;
                max-width: 350px;
                margin-top: 20px;

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                gap:20px;
            }

            .menu-aberto {
                margin: 350px 0 0 0;
                transition: 0.5s;
            }

            .menu-fechado {
                margin: 0;
                transition: 1.5s;
            }

            .menu-aberto-desktop {
                display: none;
                transition: 1s;
            }

            .titulo-hidden {
                display: none;
            }
        }

        .container-menu {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            position:relative;

            .icon-menu {
                font-size: 40px;
                line-height: 40px;
                color: #6b6b6b;
                margin: 0;
                padding: 0;
                margin-top: 15px;
                cursor: pointer;
                z-index: 20;
            }
        }

        .text-technical-manager {
            font-weight: var(--unnamed-font-style-normal);
            font-size: var(--unnamed-font-size-14);
            line-height: var(--unnamed-line-spacing-24);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-827566); 
            text-align: center;
        }

        .subtitle {
            margin-bottom: 30px;
            font-weight: var(--unnamed-font-style-normal);
            font-size: var(--unnamed-font-size-14);
            line-height: var(--unnamed-line-spacing-24);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-827566);
        }

        .logo-ailleclinic {
            width: 100vw;
            max-width: 250px;
            margin-top: 20px;
        }
    }

    main {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 36px;
    }

    @media (min-width: 800px) {
        header {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            padding: 0 36px;

            .logo-ailleclinic {
                width: 80%;
            }
        }

        .box-center {
            width: 30%;
            height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-bottom: 80px;
        }

        main {            
            .cont-prof {
                max-width: 1400px;
                width: 100%;
                display: grid;
                grid-template-columns: 2fr 2fr;
                gap: 40px;
            }
        }
    }

    @media (min-width: 1200px) {
        display: flex;
        flex-direction: column;
        align-items: center;

        header {
            width: 100%;
            padding: 0px;
            flex-direction: row;
            justify-content: space-between;
            max-width: 1400px;

            .box-center {
                width: 100%;
                max-width: 500px;
            }
        }

        main {
            .cont-prof {
                gap: 80px;
            }
        }
    }
`;

export const Banner = styled.div`
    width: 100%;
    height: 80vh;
    max-width: 600px;
    max-height: 110vh;
    background-image: ${(p) => `url(${p.image})`};
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat;
    
    @media (min-width: 800px) {
        width: 70%;
        max-width: 1200px;
    }

`;