import React from 'react';
import { Container } from './styled';
import icon1 from "../../assets/images/icon1.svg";
import icon2 from "../../assets/images/icon2.svg";
import icon3 from "../../assets/images/icon3.svg";
import icon4 from "../../assets/images/icon4.svg";

function IconHolder() {
    return (
        <Container>
            <div className="box1"><img src={icon1} alt="" className="services-icons" /><p>Cirurgia Plástica</p></div>
            <div className="box1"><img src={icon3} alt="" className="services-icons" /><p>Dermatologia</p></div>          
            <div className="box1"><img src={icon4} alt="" className="services-icons" /><p>Centro Avançado Capilar</p></div>
            <div className="box1"><img src={icon2} alt="" className="services-icons" /><p>Medical Costmetics</p></div>           
            
        </Container>
    );
}

export default IconHolder;