import styled from "styled-components";
export const Container = styled.div`
    width: 100%;
    margin: 30px 0;
    max-width: 600px;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    
    .services-icons{
        font-size:50px;
        color:var(--unnamed-color-d5b68f);
        margin-bottom:10px;
        width: 49px;
        height: 49px;
    }

    .box1{
        text-align: center;
    }
    p{
        font-size:14px;
        color:var(--unnamed-color-4a3f32);
    }
   
`