import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    margin: 50px 0;

    .image-profi {
        width: 100%;
    }

    .container-infos {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 30px 0;
    }

    .title-name {
        margin: 10px 0;
        font-style: var(--unnamed-font-style-normal);
        font-weight: var(--unnamed-font-weight-600);
        font-size: var(--unnamed-font-size-18);
        line-height: var(--unnamed-line-spacing-18);
        letter-spacing: var(--unnamed-character-spacing-1);
        color: var(--unnamed-color-d5b68f);
        text-transform: uppercase;
    }
    
    .subtitle-name {
        font-weight: var(--unnamed-font-weight-600);
        font-size: var(--unnamed-font-size-14);
        line-height: var(--unnamed-line-spacing-14);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-827566);
        margin: 0;
    }
    
    .subtitle-name:nth-child(3) {
        color: var(--unnamed-color-827566);
        margin: 10px 0 0 0;
    }

    .container-icons {
        display: flex;
        align-items: center;
        
        .insta-icon,
        .linkedin-icon {
            color: var(--unnamed-color-d5b68f);
            font-size: var(--unnamed-font-size-24);
        }

        .insta-icon {
            margin-left: 15px;
        }
    }

    .description {
        font-weight: var(--unnamed-font-style-normal);
        font-size: var(--unnamed-font-size-14);
        line-height: var(--unnamed-line-spacing-24);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-4a3f32);
        margin: 0;
    }
`;