import styled from "styled-components";

export const Image = styled.div`
    width: 100%;
    height: 60vw;
    background-image: ${props => props.src && `url(${props.src})`}};
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat;
    mix-blend-mode: multiply;
    opacity: 1;

    @media (min-width: 800px) {
        margin-top: 0px;
        width: 55%;
        height: 35vw;
        max-height: 550px;
    }
`;

export const Container = styled.div`
    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 600px;
    padding: 36px;

    .title {
        margin: 20px 0;
        margin-top: 30px;
        font-style: var(--unnamed-font-style-normal);
        font-weight: var(--unnamed-font-weight-600);
        font-size: var(--unnamed-font-size-18);
        line-height: var(--unnamed-font-size-20);
        letter-spacing: var(--unnamed-character-spacing-1);
        color: var(--unnamed-color-d5b68f);
        text-transform: uppercase;
    }

    .subtitle {
        margin: 20px 0;
        font-style: var(--unnamed-font-style-normal);
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #000;
        text-transform: uppercase;
    }

    p {
        font-weight: var(--unnamed-font-style-normal);
        font-size: var(--unnamed-font-size-14);
        line-height: var(--unnamed-line-spacing-24);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-4a3f32);
        margin: 0;
    }

    p + p {
        margin-top: 25px;
    }
    .slider{
        position:relative;
        width:100%;
        max-width:600px;

        display: flex;
        align-items: center;
        
    }
    .slider img{
        max-height:600px;
        width:100%;
    }

    
    .slider .arrow{
        background-color:transparent;
        border:none;
        height:50px;
        width:50px;
        position:absolute;
    }

    @media(max-width:800px){
        .slider{
            width:100%;
        }

        .slider .arrow{
            top:40%;
        }   
    }

    .slider .arrow-right{
        right:0;
    }

    .slider .arrow-left{
        left:0;
    }

    .slider .arrow img{
        width:50px;
        height:50px
    }

    .slider .arrow-right img{
        transform: rotate(180deg);
    }

    @media (min-width: 800px) {
        ${(p) => p.type === 1 ? `
            flex-direction: row-reverse;
            justify-content: space-between;
            max-width: 1400px;
            margin-top: 20px;

            .container-desk-text {
                width: 45%;
                display: flex;
                flex-direction: column;
                align-items: center;

                .box-info-tam {
                    margin-top: 50px;
                    max-width: 600px;
                    padding: 0 100px;
                }
            }
        ` : p.type === 2 ? `
            flex-direction: row;
            justify-content: space-between;
            max-width: 1400px;
            margin-top: 90px;
            margin-bottom: 60px;


            .container-desk-text {
                width: 45%;
                display: flex;
                flex-direction: column;
                align-items: center;

                .box-info-tam {
                    max-width: 420px;
                    margin-top: 0px;
                    padding: 0 30px;
                }
            }
        ` : null}
    }
`;