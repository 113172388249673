import React from 'react';
import { Container, Image } from './styled';
import TinySlider from 'tiny-slider-react';
import arrow from '../../assets/images/arrow.svg';


function CardText({ image, title, subtitle, menssage, type = 1 }) {
    const settings = {
        lazyload: true,
        nav: false,
        mouseDrag: true,
        prevButton: '.slider .arrow-left',
        nextButton: '.slider .arrow-right',
        gutter: 1
    };
    //const data = Array.of(image)
    console.log(image);
    return (
        <Container type={type} >
            {type === 1 ? <Image src={image} /> :
                <div className="slider" id={type === 2 ? 'a-clinica' : ''}>
                    <TinySlider settings={settings}>
                        <div key="1" style={{ position: "relative" }}>
                            <img
                                src={image[0]}
                                alt=""
                            />
                        </div>
                        <div key="2" style={{ position: "relative" }}>
                            <img
                                src={image[1]}
                                alt=""
                            />
                        </div>
                        <div key="3" style={{ position: "relative" }}>
                            <img
                                src={image[2]}
                                alt=""
                            />
                        </div>
                        <div key="4" style={{ position: "relative" }}>
                            <img
                                src={image[3]}
                                alt=""
                            />
                        </div>
                        <div key="5" style={{ position: "relative" }}>
                            <img
                                src={image[4]}
                                alt=""
                            />
                        </div>
                        <div key="6" style={{ position: "relative" }}>
                            <img
                                src={image[5]}
                                alt=""
                            />
                        </div>
                        <div key="7" style={{ position: "relative" }}>
                            <img
                                src={image[6]}
                                alt=""
                            />
                        </div>
                        <div key="8" style={{ position: "relative" }}>
                            <img
                                src={image[7]}
                                alt=""
                            />
                        </div>
                        <div key="9" style={{ position: "relative" }}>
                            <img
                                src={image[8]}
                                alt=""
                            />
                        </div>
                        <div key="10" style={{ position: "relative" }}>
                            <img
                                src={image[9]}
                                alt=""
                            />
                        </div>
                        <div key="11" style={{ position: "relative" }}>
                            <img
                                src={image[10]}
                                alt=""
                            />
                        </div>
                        <div key="12" style={{ position: "relative" }}>
                            <img
                                src={image[11]}
                                alt=""
                            />
                        </div>
                    </TinySlider>
                    <button className="arrow arrow-left"><img src={arrow} alt="" /></button>
                    <button className="arrow arrow-right"><img src={arrow} alt="" /></button>
                </div>}
            <div className="container-desk-text">
                <div className="box-info-tam">
                    {type === 1 ? title.map((txto) => <h1 className="title">{txto}</h1>) : title.map((txto) => <h2 className="title">{txto}</h2>)}

                    {subtitle && <h3 className="subtitle">{subtitle}</h3>}

                    {menssage.map((par, i) => <p key={i}>{par}</p>)}
                </div>
            </div>
        </Container>
    );
}

export default CardText;