import styled from "styled-components";

export const ContainerFooter = styled.footer`
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;

   .container-infos-contato {
      padding: 0 36px;
      max-width: 672px;
      margin: 50px 0px;

      .image-footer {
         width: 100%;
         margin-bottom: 10px;
      }

      .title {
         margin: 30px 0;
         font-style: var(--unnamed-font-style-normal);
         font-weight: var(--unnamed-font-weight-600);
         font-size: var(--unnamed-font-size-18);
         line-height: var(--unnamed-line-spacing-18);
         letter-spacing: var(--unnamed-character-spacing-1);
         color: var(--unnamed-color-d5b68f);
         text-transform: uppercase;
      }

      .description {
         font-weight: var(--unnamed-font-style-normal);
         font-size: var(--unnamed-font-size-14);
         line-height: var(--unnamed-line-spacing-24);
         letter-spacing: var(--unnamed-character-spacing-0);
         color: var(--unnamed-color-4a3f32);
         margin: 0;
      }
      .description b{
         color: var(--unnamed-color-827566);

      }
   }

   .container-direitos {
      width: 100%;
      padding: 50px 30px;
      background: #F7F4F0 0% 0% no-repeat padding-box;

      p {
         text-align: center;
         font-weight: var(--unnamed-font-style-normal);
         font-size: var(--unnamed-font-size-14);
         line-height: var(--unnamed-line-spacing-24);
         letter-spacing: var(--unnamed-character-spacing-0);
         color: var(--unnamed-color-4a3f32);
         margin: 0;
      }

      .signature {
         text-decoration: none;
         color: #000;
      }
   }

   @media (min-width: 800px) {
      .container-infos-contato {
         width: 100%;
         display: flex;
         justify-content: space-between;
         align-items: center;
         padding: 0px;
         max-width: 1400px;
         margin-bottom: 100px;

         .image-footer {
            width: 60%;
            margin-bottom: 0px;
         }

         .title {
            margin-top: 0px;
         }

         .container-posi-footer {
            width: 40%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .max-tam-footer {
               max-width: 350px;
            }
         }
      }
   }
 `;